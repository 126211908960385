import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { courtGraphicTypes } from 'match/_shared/matchConstants';
import { zeroOrMore } from 'match/utils/transformations';
import { scoutTabTypes } from 'scout/_shared/scoutConstants';

import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { checkPermission } from '_shared/utils/permissions';
import { convertBooleanToYesNo } from '_shared/utils/stringUtil';

export function getMainMenuItems(menuItemsConfig, featureFlags, permissions) {
  return menuItemsConfig.filter((menuItem) => {
    if (menuItem.featureFlag) {
      if (!featureFlags[menuItem.featureFlag]) {
        return false;
      }
    }
    if (menuItem.permission) {
      return checkPermission(menuItem.permission, permissions);
    }
    return true;
  });
}

export function getSearchParamUrl(scoutFormValues) {
  if (isEmpty(scoutFormValues)) return '';
  let url = '';
  url = url.concat(`seasons=${convertMultiSelectParam(scoutFormValues.seasons)}`);
  url = url.concat(`&surfaces=${convertMultiSelectParam(scoutFormValues.surfaces)}`);
  url = url.concat(`&last_no_matches=${convertTextParam(scoutFormValues.lastNoMatches)}`);
  url = url.concat(`&tournament_ids=${convertMultiSelectParam(scoutFormValues.tournamentIds)}`);
  url = url.concat(`&opponent_player_ids=${convertMultiSelectParam(scoutFormValues.opponentPlayerIds)}`);
  url = url.concat(`&result=${convertMultiSelectParam(scoutFormValues.result)}`);
  url = url.concat(`&rounds=${convertMultiSelectParam(scoutFormValues.rounds)}`);
  url = url.concat(`&opponent_rank=${convertMultiSelectParam(scoutFormValues.opponentRank)}`);
  url = url.concat(`&opponent_hand=${convertMultiSelectParam(scoutFormValues.opponentHand)}`);
  url = url.concat(`&match_ids=${convertMultiSelectParam(scoutFormValues.matchIds)}`);
  return url;
}

export function getCourtGraphicsParamUrl(courtGraphicsFiltersValues) {
  if (isEmpty(courtGraphicsFiltersValues)) return '';
  let url = '';
  url = url.concat(`serve=${convertTextParam(courtGraphicsFiltersValues.serve)}`);
  url = url.concat(`&serve_plus_one=${convertBooleanToYesNo(courtGraphicsFiltersValues.servePlusOne)}`);
  url = url.concat(`&pressure_break=${convertTextParam(courtGraphicsFiltersValues.pressureBreak)}`);
  url = url.concat(`&shot_type=${convertTextParam(courtGraphicsFiltersValues.forehandBackhand)}`);
  url = url.concat(`&orientation=${convertTextParam(courtGraphicsFiltersValues.orientation)}`);
  url = url.concat(`&out_shots=${convertBooleanToYesNo(courtGraphicsFiltersValues.outShots)}`);
  url = url.concat(`&serve_location=${courtGraphicsFiltersValues.serveLocation}`);
  return url;
}

export function convertMultiSelectParam(param) {
  if (!param) return '';
  else return param.toString();
}

export function convertTextParam(param) {
  if (!param) return '';
  else return param.toString();
}

export function convertSearchParams(searchParams) {
  const result = {
    seasons: stringToArr(searchParams?.seasons),
    surfaces: stringToArr(searchParams?.surfaces),
    lastNoMatches: searchParams?.lastNoMatches ? searchParams.lastNoMatches : '',
    tournamentIds: stringToArr(searchParams?.tournamentIds),
    opponentPlayerIds: stringToArr(searchParams?.opponentPlayerIds),
    result: stringToArr(searchParams?.result),
    rounds: stringToArr(searchParams?.rounds),
    opponentRank: stringToArr(searchParams?.opponentRank),
    opponentHand: stringToArr(searchParams?.opponentHand),
    matchIds: stringToArr(searchParams?.matchIds)
  };
  return result;
}

export function convertCourtParams(searchParams) {
  const result = {
    serve: searchParams?.serve ? parseInt(searchParams.serve) : 1,
    servePlusOne: searchParams?.servePlusOne === 'yes',
    pressureBreak: searchParams?.pressureBreak ? searchParams.pressureBreak : 'all',
    forehandBackhand: searchParams?.forehandBackhand ? searchParams.forehandBackhand : 'all',
    orientation: searchParams?.orientation ? searchParams.orientation : 'horizontal',
    averagesType: searchParams?.averagesType ? searchParams.averagesType : 'placement',
    outShots: searchParams?.outShots === 'yes'
  };
  return result;
}

export function getCourtMutationObject(graphicType, formValues, courtGraphicsFiltersValues) {
  if (!courtGraphicsFiltersValues) return { graphicType, courtGraphicsParams: getSearchParamUrl(formValues) };
  return {
    graphicType,
    courtGraphicsParams: `${getSearchParamUrl(formValues)}&${getCourtGraphicsParamUrl(courtGraphicsFiltersValues)}`
  };
}

export const stringToArr = (input) => {
  if (!input) return [];
  const myStr = input.toString();
  if (isEmpty(myStr)) return [];
  return myStr.split(',').map((x) => x.trim());
};

export const parseArrayToNumericValues = (searchParam) => {
  if (searchParam === undefined) return [];

  const paramString = Array.isArray(searchParam) ? searchParam.join(',') : String(searchParam);
  return paramString
    .split(',')
    .map(Number)
    .filter((number) => !isNaN(number) && number !== 0); // Filter out NaN values and 0
};

export function convertFormStateToScoutState(formUpdatedState) {
  const result = {};
  const keys = Object.keys(formUpdatedState);
  keys.forEach((item) => {
    if (item === 'seasons') {
      result['seasons'] = formUpdatedState['seasons'].map((season) => (season.id ? season.id : season));
    } else {
      if (Array.isArray(formUpdatedState[item])) {
        result[item] = arrayWithIdsToFlat(formUpdatedState[item]);
      } else {
        result[item] = formUpdatedState[item];
      }
    }
  });
  return result;
}

export function convertFormStateToPageUrlParams(scoutState) {
  const result = {};
  const keys = Object.keys(scoutState);
  keys.forEach((item) => {
    if (Array.isArray(scoutState[`${item}`])) {
      result[`${item}`] = scoutState[`${item}`].toString();
    } else if (typeof scoutState[`${item}`] === 'boolean') {
      result[`${item}`] = scoutState[`${item}`] === true ? 'yes' : 'no';
    } else {
      result[`${item}`] = scoutState[`${item}`];
    }
  });
  return result;
}

export function arrayWithIdsToFlat(array) {
  let result = [];
  if (!isEmpty(array) && array[0].id != null) {
    array.forEach((item) => {
      result.push(item.id);
    });
  } else if (!isEmpty(array) && typeof array[0] === 'string') {
    array.forEach((item) => {
      result.push(item);
    });
  } else if (!isEmpty(array) && typeof array[0] === 'number') {
    array.forEach((item) => {
      result.push(item.toString());
    });
  }
  return result;
}

export function updateMatchIds(_matchIds, _item) {
  const item = _item.toString();
  if (!Array.isArray(_matchIds)) return [];
  let matchIds = [..._matchIds];
  if (isEmpty(matchIds)) {
    matchIds.push(item);
  } else if (!matchIds.includes(item)) {
    matchIds.push(item);
  } else {
    matchIds = matchIds.filter((a) => a !== item);
  }
  return matchIds;
}

export function removeMatchIdsFromParams(formParams) {
  if (isEmpty(formParams)) return {};
  const updated = { ...formParams };
  delete updated.matchIds;
  return updated;
}

export function matchHeaderFieldsChanged(scoutFormValues, formState) {
  if (!scoutFormValues || !formState) return false;
  const fieldsChanged =
    !isEqual(scoutFormValues.seasons, formState.seasons) ||
    !isEqual(scoutFormValues.lastNoMatches, formState.lastNoMatches) ||
    !isEqual(scoutFormValues.surfaces, formState.surfaces);
  return fieldsChanged;
}

export function getLostScore(wonScore) {
  if (!zeroOrMore(wonScore)) return null;
  return 100 - wonScore;
}

export function handleTimeFrameChange(current, newState) {
  if (isWimbPortal()) return newState;
  return {
    ...newState,
    seasons: newState.seasons && newState.seasons.length > 0 ? newState.seasons : ['last_52_weeks']
  };
}

export function getGraphicTypeFromTab(tab) {
  switch (tab) {
    case scoutTabTypes.SERVE:
      return courtGraphicTypes.SERVE_DIRECTION;
    case scoutTabTypes.RETURN:
      return courtGraphicTypes.RETURN_PLACEMENT;
    case scoutTabTypes.GROUNDSTROKES:
      return courtGraphicTypes.RALLY_PLACEMENT;
    default:
      return null;
  }
}

export const getScoutPlayerOpponentsButtons = (data) => {
  return [
    {
      label: 'Player',
      value: 'player'
    },
    {
      label: 'Opponents',
      value: 'opponents'
    }
  ];
};
