import MatchWinner4x5 from './components/graphicTemplates/matchWinner/MatchWinner4x5';
import MatchWinner9x16 from './components/graphicTemplates/matchWinner/MatchWinner9x16';
import PlayerRankings4x5 from './components/graphicTemplates/playerRanking/PlayerRanking4x5';
import PlayerRanking9x16 from './components/graphicTemplates/playerRanking/PlayerRanking9x16';
import { AspectRatio, GraphicType, MatchWinner, PlayerRankings } from './components/types';

export const GRAPHIC_COMPONENTS: Record<
  GraphicType,
  Record<AspectRatio, React.FC<{ data: MatchWinner | PlayerRankings }>>
> = {
  [GraphicType.MATCH_WINNER]: {
    [AspectRatio['4x5']]: MatchWinner4x5,
    [AspectRatio['9x16']]: MatchWinner9x16
  },
  [GraphicType.PLAYER_RANKING]: {
    [AspectRatio['4x5']]: PlayerRankings4x5,
    [AspectRatio['9x16']]: PlayerRanking9x16
  }
};
