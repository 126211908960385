// Contains any vars to be used globally

export const globalConfig = {
  features: {
    matchStatistics: {
      enabled: true
    },
    performanceGraph: {
      enabled: true
    },
    performanceRating: {
      enabled: true
    },
    momentum: {
      enabled: true
    },
    scoreRangeFilter: {
      enabled: true
    },
    shotVariation: {
      enabled: true
    },
    serveEffectiveness: {
      enabled: true
    },
    courtGraphics: {
      enabled: true
    },
    matchReport: {
      enabled: true
    },
    csvReport: {
      enabled: true
    },
    serveAndReturnGamePerformance: {
      enabled: false
    },
    leaderboard: {
      enabled: true
    },
    winningAndLosingPlaysV2: {
      enabled: true
    },
    patternsOfPlay: {
      enabled: false
    },
    performanceOverview: {
      enabled: true
    },
    serveV2: {
      enabled: false
    },
    matchCentreVideoTab: {
      enabled: true
    },
    tournaments: {
      enabled: true
    },
    scout: {
      enabled: true
    },
    scoutReport: {
      enabled: true
    },
    termsAndConditions: {
      enabled: true
    },
    headToHead: {
      enabled: true
    },
    wearablesLink: {
      enabled: true
    },
    graphics_creator: {
      enabled: false
    }
  }
};
