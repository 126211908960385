export type GraphicData = {
  image_url_atptour: string;
  first_name: string;
  last_name: string;
  initial: string;
};

export interface MatchWinner extends GraphicData {
  tournament_logo_url: string;
  competition_name: string;
  competition_country: string;
  round: string;
  score: string;
  opponent_first_name: string;
  opponent_last_name: string;
  opponent_initial: string;
  surface: string;
}

export interface PlayerRankings extends GraphicData {
  ranking: number;
  points: number;
  places_changed: number;
  date_of_ranking: string;
}

export type GraphicContainerProps = {
  graphicType: GraphicType;
  aspectRatio: AspectRatio;
  data: MatchWinner | PlayerRankings;
  isLoading?: boolean;
};

export enum FormStage {
  INITIAL = 'initial',
  FORM_OPTIONS_GENERATED = 'formOptionsGenerated',
  GRAPHIC_GENERATED = 'graphicGenerated'
}

export enum GraphicType {
  MATCH_WINNER = 'match_winner',
  PLAYER_RANKING = 'player_ranking'
}

export enum AspectRatio {
  '4x5' = '4x5',
  '9x16' = '9x16'
}

export interface DynamicField {
  field_name: string;
  field_value: string;
  type: string;
}
