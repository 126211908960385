import { MatchWinner } from 'graphics/components/types';
import { MATCH_WINNER_ASSETS_PATH } from 'graphics/graphicsAssets';
import { matchWinnerColors } from 'graphics/graphicsColors/matchWinnerColors';
import { getCountryName } from 'graphics/utils/countryNames';
import { getCountryFlagComponent } from 'graphics/utils/getCountryFlagComponent';

import { Box, Text, Flex, Image } from '_shared/designSystem/components';

type MatchWinner4x5Props = {
  data: MatchWinner;
};

const ASSETS = {
  gridLines: `${MATCH_WINNER_ASSETS_PATH}/4x5/GridLines.png`,
  atpTourLogo: `${MATCH_WINNER_ASSETS_PATH}/4x5/ATP-logo.png`,
  courtSurfaces: {
    hard: `${MATCH_WINNER_ASSETS_PATH}/4x5/courtBackgrounds/Hard.png`,
    clay: `${MATCH_WINNER_ASSETS_PATH}/4x5/courtBackgrounds/Clay.png`,
    grass: `${MATCH_WINNER_ASSETS_PATH}/4x5/courtBackgrounds/Grass.png`
  },
  frayedLayer: `${MATCH_WINNER_ASSETS_PATH}/4x5/BottomImageFrayedLayer.png`,
  scribble: `${MATCH_WINNER_ASSETS_PATH}/4x5/Scribble.png`,
  overlay: `${MATCH_WINNER_ASSETS_PATH}/4x5/Landscape-Noise.png`
};

const MatchWinner4x5 = ({ data }: MatchWinner4x5Props) => {
  const courtSurface = ASSETS.courtSurfaces[data.surface || 'hard'];
  const competitionCountry = getCountryName(data.competition_country);
  const countryFlag = getCountryFlagComponent(data.competition_country);
  const longName = data.last_name?.length > 10;

  return (
    <Box bg={matchWinnerColors.background} height="1350px" width="1080px" position="relative">
      {/* Grid Lines */}
      <Image src={ASSETS.gridLines} alt="Gridlines" width="100%" height="auto" position="absolute" top="0" left="0" />
      <Image
        src={ASSETS.gridLines}
        alt="Gridlines"
        width="100%"
        height="auto"
        position="absolute"
        top="216px"
        left="0"
      />

      {/* Top Section */}
      <Flex position="absolute" top="0" width="100%" height="992px">
        <Flex width="166px" height="992px" flexDirection="column" justifyContent="center" alignItems="center">
          <Box transform="rotate(-90deg)" textAlign="center">
            <Text
              mt={longName ? '-60px' : -3}
              fontSize={longName ? 55 : 80}
              lineHeight={1}
              fontFamily="ThermalExtraLight"
            >
              {data.first_name?.toUpperCase()}
            </Text>
            <Text
              fontSize={longName ? 68 : 98}
              lineHeight={longName ? 0.9 : 0.9}
              fontFamily="RocGroteskWideExtraBold"
              whiteSpace="nowrap"
            >
              {data.last_name?.toUpperCase()}
            </Text>
          </Box>
        </Flex>

        <Flex width="914px" height="992px" position="relative">
          <Image src={courtSurface} alt="Court surface" width="100%" height="auto" />
          <Image
            src={data.image_url_atptour}
            alt={`${data.first_name} ${data.last_name}`}
            width="100%"
            height="auto"
            position="absolute"
            top="60px"
            left="0"
          />
          <Image
            src={ASSETS.frayedLayer}
            alt="Bottom layer"
            position="absolute"
            bottom="-132px"
            width="914px"
            clipPath="inset(0 0 75% 0)"
          />
        </Flex>
      </Flex>

      {/* Scribble */}
      <Image src={ASSETS.scribble} width="350px" height="200px" position="absolute" bottom="275px" right="28px" />

      {/* Middle Section */}
      <Flex position="absolute" bottom="163px" width="100%" height="155px">
        <Flex width="166px" height="138px" justifyContent="center" alignItems="center">
          <Flex
            width="70px"
            height="70px"
            border="2px solid"
            borderColor={matchWinnerColors.borderColor}
            borderRadius="50px"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontFamily="ThermalExtraLight" fontSize="2xl">
              {data.round}
            </Text>
          </Flex>
        </Flex>

        <Flex width="914px">
          <Flex
            width="70%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            paddingX={12}
            lineHeight={1.1}
          >
            <Text fontSize={47} fontFamily="ThermalExtraLight">
              d. {data.opponent_last_name?.toUpperCase()}
            </Text>
            <Text fontSize={55} fontFamily="RocGroteskWideExtraBold">
              {data.score}
            </Text>
          </Flex>

          <Flex width="30%" justifyContent="center" alignItems="center" position="absolute" right="0" top="5">
            <Image src={data.tournament_logo_url} alt="Tournament logo" />
          </Flex>
        </Flex>
      </Flex>

      {/* Bottom Section */}
      <Flex position="absolute" bottom="0" width="100%" height="140px">
        <Flex width="75%" justifyContent="flex-start" alignItems="center" paddingX={14} gap={6}>
          {countryFlag}
          <Text pt="6px" fontSize={35} fontFamily="ThermalExtraLight">
            <span style={{ fontFamily: 'RocGroteskExtraBold' }}>
              {data.competition_name?.toUpperCase()}
              {competitionCountry && ','}
            </span>{' '}
            {competitionCountry}
          </Text>
        </Flex>

        <Flex width="25%" justifyContent="center" alignItems="center">
          <Image src={ASSETS.atpTourLogo} alt="ATP Tour logo" height="49px" width="165px" />
        </Flex>
      </Flex>

      {/* Overlay */}
      <Image src={ASSETS.overlay} height="1350px" width="1080px" position="absolute" top="0" left="0" />
    </Box>
  );
};

export default MatchWinner4x5;
