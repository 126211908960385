import React, { useState, useEffect } from 'react';

import { useNavigate } from '@tanstack/react-location';
import { DEFAULT_MATCH_ID, ORIGINAL_HEIGHT, ORIGINAL_WIDTH } from 'graphics/constants';
import { graphicsService } from 'graphics/services/graphicsService';

import { Box, Flex } from '_shared/designSystem/components';
import usePageTitle from '_shared/utils/hooks/usePageTitle';

import GraphicContainer from './GraphicContainer';
import GraphicForm from './GraphicForm';
import GraphicSkeleton from './GraphicSkeleton';
import ResponsiveContainer from './ResponsiveContainer';
import { AspectRatio, GraphicType, MatchWinner, PlayerRankings } from './types';

const Graphics: React.FC = () => {
  usePageTitle('Graphics');

  const navigate = useNavigate();

  const getDefaultQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const graphicType = searchParams.get('graphic_type') || GraphicType.MATCH_WINNER;
    const matchId = searchParams.get('match_id') || DEFAULT_MATCH_ID;

    // Validate aspectRatio and default to '4x5' if invalid
    const aspectRatioParam = searchParams.get('aspect_ratio') as AspectRatio;
    const validAspectRatios = Object.values(AspectRatio);
    const aspectRatio = validAspectRatios.includes(aspectRatioParam) ? aspectRatioParam : AspectRatio['4x5'];

    return { graphicType, match_id: matchId, aspectRatio };
  };

  const initialParams = getDefaultQueryParams();

  const [graphicData, setGraphicData] = useState<MatchWinner | PlayerRankings | null>(null);
  const [graphicType, setGraphicType] = useState<GraphicType>(initialParams.graphicType as GraphicType);
  const [aspectRatio, setAspectRatio] = useState<AspectRatio>(initialParams.aspectRatio);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const params = getDefaultQueryParams();

    // Update URL if invalid or missing parameters
    const searchParams = new URLSearchParams(window.location.search);
    const currentAspectRatio = searchParams.get('aspect_ratio');
    if (!currentAspectRatio || currentAspectRatio !== params.aspectRatio) {
      navigate({
        to: '/graphics',
        search: {
          graphic_type: params.graphicType,
          match_id: params.match_id,
          aspect_ratio: params.aspectRatio,
          replace: true
        }
      });
    }

    // Fetch graphic data using the (defaulted) match id and graphic type
    graphicsService
      .generateGraphicData({
        queryParams: {
          graphicType: params.graphicType,
          match_id: params.match_id
        }
      })
      .then((data: MatchWinner | PlayerRankings) => {
        setGraphicData(data);
        setGraphicType(params.graphicType as GraphicType);
        setErrorMessage(null);
      })
      .catch((error) => {
        const status = error.response?.status;
        if (status === 400 || status === 403) {
          setErrorMessage(error.response?.data?.message);
        } else {
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
        setGraphicData(null);
      });
  }, [navigate, graphicType, aspectRatio]);

  // Handle updates coming from the GraphicForm.
  const handleGraphicUpdate = (
    newData: MatchWinner | PlayerRankings,
    newGraphicType: GraphicType,
    newAspectRatio: AspectRatio,
    match_id: string
  ) => {
    setGraphicData(newData);
    setGraphicType(newGraphicType);
    setAspectRatio(newAspectRatio);
    setErrorMessage(null);
    navigate({
      to: '/graphics',
      search: {
        graphic_type: newGraphicType,
        match_id: match_id,
        aspect_ratio: newAspectRatio,
        replace: true
      }
    });
  };

  return (
    <Box width="100%" margin="0 auto" padding={4}>
      <Flex direction={['column', 'row']} gap={4} align="flex-start">
        {/* Graphic Form */}
        <Box flex={['0 0 100%', '1']} maxWidth={['100%', '50%']} width="100%">
          <GraphicForm
            onGraphicGenerated={handleGraphicUpdate}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </Box>

        {/* Graphic Container */}
        <Box flex={['0 0 100%', '1']} maxWidth={['100%', '50%']} width="100%">
          <ResponsiveContainer
            originalWidth={ORIGINAL_WIDTH}
            originalHeight={ORIGINAL_HEIGHT}
            maxWidth={ORIGINAL_WIDTH}
            flex="1"
          >
            {graphicData && !errorMessage ? (
              <GraphicContainer graphicType={graphicType} aspectRatio={aspectRatio} data={graphicData} />
            ) : (
              <GraphicSkeleton />
            )}
          </ResponsiveContainer>
        </Box>
      </Flex>
    </Box>
  );
};

export default Graphics;
