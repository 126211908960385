import React, { useRef, useEffect } from 'react';

import { select, scaleLinear, arc } from 'd3';
import { displayScore } from 'match/utils/transformations';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Text, HStack, Flex, Alert } from '_shared/designSystem/components';
import { tvD3ComponentColors } from '_shared/designSystem/theme/chartColors';

import { NO_SHOT_QUALITY_PLAYER_AVG_MESSAGE } from './radarConstants';
import { mergeDataWithAverages, getCoordinates, isShotQualityPlayerAvgPresent } from './radarUtils';
import { RadarData, RadarProps } from './types';

const Radar: React.FC<RadarProps> = ({ dataNoAverages, dataWithAverages, showAverages, isLoading }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const colors = customSitesConfig?.d3ComponentColors || tvD3ComponentColors;
  const { shared: sharedColours, radar: radarColours } = colors;

  const mergedData = mergeDataWithAverages(dataNoAverages, dataWithAverages);

  const width = 650;
  const height = 650;
  const radius = Math.min(width, height - 100) / 2;
  const outerCircleWidth = 115;
  const levels = 4;
  const dotRadius = 8;
  const radarLinesWidth = 6;
  const circlesBackgroundOpacity = 0.08;
  const circlesBorderOpacity = 0.3;

  function adjustAreaOpacity(opacity: number): number {
    return showAverages ? opacity / 2 : opacity;
  }

  // Create tooltip element and store reference
  useEffect(() => {
    if (!tooltipRef.current) {
      const tooltip = document.createElement('div');
      tooltip.id = 'd3-tooltip';
      tooltip.style.position = 'fixed'; // Changed from 'absolute' to 'fixed'
      tooltip.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltip.style.color = 'white';
      tooltip.style.padding = '5px 10px';
      tooltip.style.borderRadius = '4px';
      tooltip.style.pointerEvents = 'none';
      tooltip.style.fontSize = '12px';
      tooltip.style.opacity = '0';
      tooltip.style.transition = 'opacity 0.2s ease-in-out';
      tooltip.style.zIndex = '9999'; // Add z-index to ensure visibility

      document.body.appendChild(tooltip);
      tooltipRef.current = tooltip;
    }

    return () => {
      if (tooltipRef.current) {
        document.body.removeChild(tooltipRef.current);
        tooltipRef.current = null;
      }
    };
  }, []);

  const handleTooltipShow = (event: MouseEvent, d: RadarData, type: string) => {
    if (!tooltipRef.current) return;

    const content =
      type === 'tour_average'
        ? `${displayScore(d.tour_average, d.score_type)}`
        : `${displayScore(d.player_average, d.score_type)}`;
    const backgroundColor = type === 'tour_average' ? sharedColours.tourAverage : sharedColours.playerAverage;

    tooltipRef.current.style.left = `${event.clientX - 40}px`; // Using clientX instead of pageX
    tooltipRef.current.style.top = `${event.clientY - 40}px`; // Using clientY instead of pageY
    tooltipRef.current.style.background = backgroundColor;
    tooltipRef.current.style.color = backgroundColor === sharedColours.tourAverage ? 'white' : 'black';
    tooltipRef.current.innerHTML = content;
    tooltipRef.current.style.opacity = '1';
  };

  const handleTooltipHide = () => {
    if (tooltipRef.current) {
      tooltipRef.current.style.opacity = '0';
    }
  };

  const noShotQualityPlayerAvg = !isShotQualityPlayerAvgPresent(mergedData);

  useEffect(() => {
    if (!isLoading && Array.isArray(mergedData) && mergedData.length > 0) {
      // Clear any existing SVG content
      select(svgRef.current).selectAll('*').remove();

      // Define the SVG with viewBox for responsiveness
      const svg = select(svgRef.current)
        .attr('viewBox', `0 0 ${width + 155} ${height + 165}`) // Original dimensions plus padding
        .attr('preserveAspectRatio', 'xMidYMid meet') // Maintain aspect ratio
        .append('g')
        .attr('transform', `translate(${(width + 155) / 2}, ${(height + 155) / 2})`);

      const angleSlice = (Math.PI * 2) / mergedData.length; // Angle per axis

      // Define a fixed minimum scale
      const minScale = 0.2 * radius;

      // Create a linear scale for each data point
      const scales = mergedData.map(() => scaleLinear().domain([0, 1]).range([minScale, radius]));

      // Draw outer background circle
      svg
        .append('circle')
        .attr('r', radius + outerCircleWidth) // Original value
        .attr('fill', radarColours.score)
        .attr('fill-opacity', 0.1)
        .attr('pointer-events', 'none');

      // add lightness to the inner circle
      svg
        .append('circle')
        .attr('r', radius) // Inner circle
        .attr('fill', 'white')
        .attr('fill-opacity', 0.5)
        .attr('pointer-events', 'none');

      const radarGroup = svg.append('g');

      // Make the single level areas, this is needed so we can keep them all the same shade
      const createSingleLevelCircularArea = (innerRadius: number, outerRadius: number) => {
        return arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(0)
          .endAngle(2 * Math.PI);
      };

      // Draw the circles levels background and borders
      for (let level = 1; level <= levels; level++) {
        const innerRadius = (radius / levels) * (level - 1);
        const outerRadius = (radius / levels) * level;

        radarGroup
          .append('path')
          .attr('d', createSingleLevelCircularArea(innerRadius, outerRadius))
          .attr('fill', radarColours.lightGrey)
          .attr('stroke', radarColours.darkGrey)
          .attr('fill-opacity', circlesBackgroundOpacity)
          .attr('stroke-opacity', circlesBorderOpacity)
          .attr('pointer-events', 'none');
      }

      // Draw axes
      const axis = radarGroup.selectAll('.axis').data(mergedData).enter().append('g').attr('class', 'axis');

      axis
        .append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', (d: RadarData, i: number) => radius * Math.cos(angleSlice * i - Math.PI / 2))
        .attr('y2', (d: RadarData, i: number) => radius * Math.sin(angleSlice * i - Math.PI / 2))
        .attr('stroke', radarColours.darkGrey)
        .attr('stroke-opacity', circlesBorderOpacity)
        .attr('stroke-width', '1px')
        .attr('pointer-events', 'none');

      // Plot tour averages polygon
      const tourPoints = mergedData.map((d: RadarData) => {
        const coord = getCoordinates(d, mergedData, scales, 'tour_average', angleSlice, minScale);
        return [coord.x, coord.y];
      });

      radarGroup
        .append('polygon')
        .attr('points', tourPoints.map((d: number[]) => d.join(',')).join(' '))
        .attr('stroke', sharedColours.tourAverage)
        .attr('stroke-width', radarLinesWidth)
        .attr('fill', sharedColours.tourAverage)
        .attr('fill-opacity', adjustAreaOpacity(radarColours.tourAvgOpacity))
        .attr('pointer-events', 'none');

      // Plot tour average circles
      radarGroup
        .selectAll('.tour-circle')
        .data(mergedData)
        .enter()
        .append('circle')
        .attr('class', 'tour-circle')
        .attr(
          'cx',
          (d: RadarData, i: number) => getCoordinates(d, mergedData, scales, 'tour_average', angleSlice, minScale).x
        )
        .attr(
          'cy',
          (d: RadarData, i: number) => getCoordinates(d, mergedData, scales, 'tour_average', angleSlice, minScale).y
        )
        .attr('r', dotRadius)
        .attr('fill', sharedColours.tourAverage)
        .attr('stroke', 'white')
        .attr('stroke-width', 2)
        .attr('cursor', 'pointer')
        .on('mouseenter', function (this: SVGCircleElement, event: MouseEvent, d: RadarData) {
          handleTooltipShow(event, d, 'tour_average');
        })
        .on('mousemove', function (event: MouseEvent, d: RadarData) {
          handleTooltipShow(event, d, 'tour_average');
        })
        .on('mouseleave', handleTooltipHide);

      // Plot score polygon
      const playerPoints = mergedData.map((d: RadarData) => {
        const coord = getCoordinates(d, mergedData, scales, 'score', angleSlice, minScale);
        return [coord.x, coord.y];
      });

      // Plot player averages polygon
      if (showAverages && !noShotQualityPlayerAvg) {
        const playerAveragePoints = mergedData.map((d: RadarData) => {
          const coord = getCoordinates(d, mergedData, scales, 'player_average', angleSlice, minScale);
          return [coord.x, coord.y];
        });

        radarGroup
          .append('polygon')
          .attr('points', playerAveragePoints.map((d: number[]) => d.join(',')).join(' '))
          .attr('stroke', sharedColours.playerAverage)
          .attr('stroke-width', radarLinesWidth)
          .attr('fill', showAverages ? sharedColours.playerAverage : 'none')
          .attr('fill-opacity', adjustAreaOpacity(radarColours.playerAvgOpacity))
          .attr('pointer-events', 'none');

        // Plot player average circles
        radarGroup
          .selectAll('.player-average-circle')
          .data(mergedData)
          .enter()
          .append('circle')
          .attr('class', 'player-average-circle')
          .attr('cx', (d: RadarData) => getCoordinates(d, mergedData, scales, 'player_average', angleSlice, minScale).x)
          .attr('cy', (d: RadarData) => getCoordinates(d, mergedData, scales, 'player_average', angleSlice, minScale).y)
          .attr('r', dotRadius)
          .attr('fill', sharedColours.playerAverage)
          .attr('stroke', 'white')
          .attr('stroke-width', 2)
          .attr('cursor', 'pointer')
          // Updated event listeners
          .on('mouseenter', function (this: SVGCircleElement, event: MouseEvent, d: RadarData) {
            handleTooltipShow(event, d, 'player_average');
          })
          .on('mousemove', function (event: MouseEvent, d: RadarData) {
            handleTooltipShow(event, d, 'player_average');
          })
          .on('mouseleave', handleTooltipHide);
      }

      if (!showAverages || mergedData.some((d) => d.player_average !== d.score)) {
        radarGroup
          .append('polygon')
          .attr('points', playerPoints.map((d: number[]) => d.join(',')).join(' '))
          .attr('stroke', radarColours.score)
          .attr('stroke-width', radarLinesWidth)
          .attr('fill', radarColours.score)
          .attr('fill-opacity', adjustAreaOpacity(radarColours.scoreOpacity))
          .attr('pointer-events', 'none');

        // Plot player circles (data points) on the radarColours
        radarGroup
          .selectAll('.player-circle')
          .data(mergedData)
          .enter()
          .append('circle')
          .attr('class', 'player-circle')
          .attr(
            'cx',
            (d: RadarData, i: number) => getCoordinates(d, mergedData, scales, 'score', angleSlice, minScale).x
          )
          .attr(
            'cy',
            (d: RadarData, i: number) => getCoordinates(d, mergedData, scales, 'score', angleSlice, minScale).y
          )
          .attr('r', dotRadius)
          .attr('fill', radarColours.score)
          .attr('stroke', 'white')
          .attr('stroke-width', 2);
      }

      // Add labels
      svg
        .selectAll('.label')
        .data(mergedData)
        .enter()
        .append('text')
        .attr('x', (_: any, i: number) => {
          const angle = angleSlice * i - Math.PI / 2;
          const offset = Math.cos(angle) !== 0 ? 60 : 0;
          return (radius + offset) * Math.cos(angle);
        })
        .attr('y', (_: any, i: number) => {
          const angle = angleSlice * i - Math.PI / 2;
          let offset = 0;
          if (Math.sin(angle) > 0) offset = 30;
          else if (Math.sin(angle) < 0) offset = 80;
          return (radius + offset) * Math.sin(angle);
        })
        .attr('font-size', width / 32.5)
        .attr('font-weight', 500)
        .attr('fill', 'black')
        .attr('font-stretch', '70%')
        .attr('text-anchor', 'middle')
        .each(function (d: { label: string; score: number; score_type: string }) {
          let words = d.label.toUpperCase().split(' ');
          if (d.label === 'Baseline Pts Won') words = ['BASELINE', 'PTS WON']; // Special case for baseline points won
          const textElement = select(this);

          words.forEach((word, index) => {
            textElement
              .append('tspan')
              .text(word)
              .attr('x', textElement.attr('x'))
              .attr('y', textElement.attr('y'))
              .attr('dy', index === 0 ? 0 : index * 24);
          });

          // Add the score percentage below the label
          textElement
            .append('tspan')
            .text(`${displayScore(d.score, d.score_type)}`) // Add the score percentage
            .attr('x', textElement.attr('x')) // Use parent <text>'s x position
            .attr('y', textElement.attr('y')) // Use parent <text>'s y position
            .attr('fill', radarColours.score)
            .attr('font-size', 37)
            .attr('dy', words.length === 1 ? 45 : 33 * words.length);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, mergedData, width, height, radius, showAverages]);

  return (
    <Box position="relative" width="100%" height="100%" overflow="hidden" ref={containerRef} mb={6}>
      {Array.isArray(mergedData) && mergedData.length > 0 ? (
        <>
          <Flex justify="space-between" align="center" direction="row" px={4} mt={4} mb={2}>
            <Text fontSize="md" fontWeight="semibold" mb={{ base: 2, md: 0 }}>
              Insights
            </Text>
            <Flex gap={4} direction="row" align="center">
              {showAverages && (
                <HStack>
                  <Box w={3} h={3} borderRadius="full" bg="playerAverage" />
                  <Text fontSize="xs">Player Avg</Text>
                </HStack>
              )}
              <HStack>
                <Box w={3} h={3} borderRadius="full" bg="tourAverage" />
                <Text fontSize="xs">Tour Avg</Text>
              </HStack>
            </Flex>
          </Flex>
          {noShotQualityPlayerAvg && showAverages && (
            <Box pt={4} pb={6}>
              <Alert status="info" message={NO_SHOT_QUALITY_PLAYER_AVG_MESSAGE} />
            </Box>
          )}
          <Box maxW="500px" margin="auto">
            <svg ref={svgRef} width="100%" height="100%"></svg>
          </Box>
        </>
      ) : (
        <Text>No data available to display the radarColours chart.</Text>
      )}
    </Box>
  );
};

export default Radar;
