import { PlayerRankings } from 'graphics/components/types';
import { PLAYER_RANKINGS_ASSETS_PATH } from 'graphics/graphicsAssets';
import { playerRankingsColors } from 'graphics/graphicsColors/playerRankingsColors';

import { Box, Text, Flex, Image } from '_shared/designSystem/components';
import { formatDateShortYear } from '_shared/utils/dateUtil';

type PlayerRanking4x5Props = {
  data: PlayerRankings;
};

const ASSETS = {
  horizontalGridLines: `${PLAYER_RANKINGS_ASSETS_PATH}/4x5/Horizontal_Line.png`,
  verticalGridLines: `${PLAYER_RANKINGS_ASSETS_PATH}/4x5/Vertical_Lines.png`,
  frayedLayer: `${PLAYER_RANKINGS_ASSETS_PATH}/4x5/Frayed_Layer.png`,
  scribble1: `${PLAYER_RANKINGS_ASSETS_PATH}/4x5/Scribble_1.png`,
  scribble2: `${PLAYER_RANKINGS_ASSETS_PATH}/4x5/Scribble_2.png`,
  overlay: `${PLAYER_RANKINGS_ASSETS_PATH}/4x5/Landscape-Noise.png`,
  atpTourLogo: `${PLAYER_RANKINGS_ASSETS_PATH}/4x5/ATP-IAAU.png`,
  pifATPRankingsLogo: `${PLAYER_RANKINGS_ASSETS_PATH}/4x5/PIF-ATP_Rankings.png`,
  circle: `${PLAYER_RANKINGS_ASSETS_PATH}/4x5/Circle.png`
};

const PlayerRankings4x5 = ({ data }: PlayerRanking4x5Props) => {
  return (
    <Box bg={playerRankingsColors.background} height="1350px" width="1080px" position="relative">
      {/* Overlay */}
      <Image src={ASSETS.overlay} position="absolute" top="0" left="0" height="1350px" width="1080px" zIndex={4} />
      {/* Grid Lines */}
      <Image
        src={ASSETS.verticalGridLines}
        alt="Gridlines"
        width="100%"
        height="auto"
        position="absolute"
        top="0"
        left="0"
        zIndex={2}
      />
      {/* Top */}
      <Flex direction="column" position="absolute" top="50px" left="50px">
        <Text fontFamily="ThermalExtraLight" fontSize="20px" lineHeight="0.8">
          {data.first_name.toUpperCase()}
        </Text>
        <Text fontFamily="RocGroteskExtraBold" fontSize="30px" lineHeight="0.8">
          {data.last_name.toUpperCase()}
        </Text>
      </Flex>

      {/* Middle */}
      <Flex w="100%" h="80%" position="absolute" top="10%">
        <Flex
          width="50%"
          height="100%"
          backgroundColor={playerRankingsColors.background}
          justifyContent="center"
          alignItems="center"
          direction="column"
          gap={16}
        >
          <Flex direction="column">
            <Image src={ASSETS.pifATPRankingsLogo} alt="pif-atp-rankings" />
          </Flex>
          <Image src={ASSETS.circle} />
          <Box position="absolute">
            <Flex w="100%" justifyContent="center" alignItems="center" flexDirection="column">
              <Text fontFamily="ThermalExtraLight" fontSize="36px">
                RANK
              </Text>
              <Text fontFamily="RocGroteskWideExtraBold" fontSize="140px" lineHeight="0.9">
                {data.ranking}
              </Text>
              {data.places_changed && data.places_changed > 0 && (
                <Box color={playerRankingsColors.increase} textAlign="center" mt={6}>
                  <Text fontFamily="RocGroteskMedium" fontSize="30px" lineHeight="0.8">
                    &#9650;
                  </Text>
                  <Text fontFamily="RocGroteskMedium" fontSize="36px">
                    +{data.places_changed}
                  </Text>
                </Box>
              )}
            </Flex>
          </Box>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Text fontFamily="ThermalExtraLight" fontSize="36px">
              POINTS
            </Text>
            <Text fontFamily="RocGroteskWideExtraBold" fontSize="72px" lineHeight="0.8">
              {data.points.toLocaleString()}
            </Text>
          </Flex>
        </Flex>
        <Image
          src={ASSETS.horizontalGridLines}
          alt="Gridlines"
          width="100%"
          height="auto"
          position="absolute"
          top="0"
          left="0"
        />
        <Box width="50%" height="100%" position="relative">
          <Image
            src={data.image_url_atptour}
            alt={`${data.first_name} ${data.last_name}`}
            width="120%"
            height="115%"
            objectFit="cover"
            position="absolute"
            bottom="-65px"
            left="0"
            zIndex={1}
          />
          <Image src={ASSETS.scribble1} alt="scribble1" position="absolute" top="52%" right={0} zIndex={2} />
        </Box>
      </Flex>

      {/* Bottom */}
      <Flex w="100%" h="10%" position="absolute" bottom="0">
        <Image src={ASSETS.scribble2} alt="scribble2" position="absolute" bottom="0" left="38.5%" zIndex={2} />
        <Flex justifyContent="center" alignItems="center" h="100%" w="50%">
          <Text fontFamily="RocGroteskWideMedium" fontSize="24px" zIndex={2}>
            AS OF {formatDateShortYear(data.date_of_ranking).toUpperCase()}
          </Text>
        </Flex>
        <Flex justifyContent="center" alignItems="center" h="100%" w="50%">
          <Image src={ASSETS.atpTourLogo} alt="atp tour logo" w="400px" zIndex={2} mt={2} />
        </Flex>
      </Flex>
      <Box zIndex={1} w="100%" position="absolute" bottom={0} right={0}>
        <Image src={ASSETS.frayedLayer} />
      </Box>
    </Box>
  );
};

export default PlayerRankings4x5;
