import { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import { BREAKDOWN_METRICS_TYPES } from 'match/_shared/matchConstants';
import { displayScore } from 'match/utils/transformations';
import PropTypes from 'prop-types';

import HeadingWithTooltip from '_shared/components/HeadingWithTooltip';
import customSitesConfig from '_shared/config/customSitesConfig';
import { Text, Flex, Center, ButtonGroup, Box, CourtBadge } from '_shared/designSystem/components';
import SingleStackedBar from '_shared/designSystem/components/charts/singleStackedBar/SingleStackedBar';

const middleColumnWidth = '100px';
const DEFAULT_STACK_COLORS = [
  { bg: 'primary.25', text: 'secondary.800' },
  { bg: 'primary.100', text: 'secondary.800' },
  { bg: 'primary.300', text: 'secondary.800' },
  { bg: 'primary.500', text: 'secondary.800' },
  { bg: 'primary.600', text: 'white' },
  { bg: 'primary.700', text: 'white' },
  { bg: 'primary.900', text: 'white' }
];

const BreakdownMetrics = ({
  dataNoAverages: _dataNoAverages,
  dataWithAverages: _dataWithAverages,
  showAverages,
  players,
  graphicType,
  isScout,
  serveType = 1,
  isReport,
  isPerformanceOverviewPage = false,
  ...rest
}) => {
  const isServeEffectiveness = graphicType === BREAKDOWN_METRICS_TYPES.SERVE_EFFECTIVENESS;
  const isMatch = !isScout;
  const isMatchReport = !isScout && isReport;

  const [serveEffectivenessServe, setServeEffectivenessServe] = useState(serveType);
  const [dataNoAverages, setDataNoAverages] = useState(null);
  const [dataWithAverages, setDataWithAverages] = useState(null);

  useEffect(() => {
    if (isServeEffectiveness && isMatch) {
      const serve = serveEffectivenessServe === 2 ? '2nd_serve' : '1st_serve';
      setDataNoAverages(_dataNoAverages[serve]);
      if (!isEmpty(_dataWithAverages)) {
        setDataWithAverages(_dataWithAverages[serve]);
      }
    } else {
      setDataNoAverages(_dataNoAverages);
      setDataWithAverages(_dataWithAverages);
    }
  }, [serveEffectivenessServe, _dataNoAverages, _dataWithAverages, graphicType, isMatch, isServeEffectiveness]);

  const handleFilterButtonClick = (param, value) => {
    setServeEffectivenessServe(value);
  };

  const customStackColors = isServeEffectiveness
    ? customSitesConfig?.stackedBarColors?.serveEffectiveness ?? DEFAULT_STACK_COLORS
    : customSitesConfig?.stackedBarColors?.shotVariation ?? DEFAULT_STACK_COLORS;

  const heading = isServeEffectiveness ? 'Serve Effectiveness' : 'Shot Variation';

  const serveEffectivenessTooltip =
    'An effective serve is an ace, unreturned serve, or a serve that leads to the server being in attack on the serve +1';
  const shotVariationTooltip = `Shot Variation compares the use of Core shots (topspin groundstrokes hit through the opponent's baseline) with their use of Variation (slice groundstrokes, short slices, drop shots, angles, and net shots)`;

  if (isEmpty(dataNoAverages)) return null;

  return (
    <Box>
      {!isMatchReport && !isPerformanceOverviewPage && (
        <HeadingWithTooltip
          isPerformanceOverviewPage={isPerformanceOverviewPage}
          heading={heading}
          tooltipText={isReport ? null : isServeEffectiveness ? serveEffectivenessTooltip : shotVariationTooltip}
        />
      )}
      <Flex direction="column" gap={4}>
        {isMatch && isServeEffectiveness && !isMatchReport && (
          <Center>
            <ButtonGroup
              handleButtonClick={handleFilterButtonClick}
              data={[
                { value: 1, label: '1st Serve', position: 'left' },
                { value: 2, label: '2nd Serve', position: 'right' }
              ]}
              paramName="serveEffectivenessServe"
              selectedItem={serveEffectivenessServe}
            />
          </Center>
        )}
        {isMatch && <ColumnHeadings left={players?.player1?.last_name} right={players?.player2?.last_name} />}
        {isScout && isServeEffectiveness && <ColumnHeadings left="1st Serve" right="2nd Serve" />}
        {isMatch && isServeEffectiveness && (
          <OverallRow
            left={
              <OverallBadges
                dataNoAverages={dataNoAverages?.overall}
                dataWithAverages={dataWithAverages?.overall}
                showAverages={showAverages}
                player="player1"
                lowerValue={dataNoAverages?.overall?.player1?.score < dataNoAverages?.overall?.player2?.score}
                isPerformanceOverviewPage={isPerformanceOverviewPage}
                {...rest}
              />
            }
            middle={`${serveEffectivenessServe === 2 ? '2nd Serve' : '1st Serve'} Effectiveness`}
            right={
              <OverallBadges
                dataNoAverages={dataNoAverages?.overall}
                dataWithAverages={dataWithAverages?.overall}
                showAverages={showAverages}
                player="player2"
                lowerValue={dataNoAverages?.overall?.player2?.score < dataNoAverages?.overall?.player1?.score}
                isPerformanceOverviewPage={isPerformanceOverviewPage}
                {...rest}
              />
            }
          />
        )}
        {isScout && isServeEffectiveness && (
          <OverallRow
            left={
              <OverallBadges
                dataNoAverages={dataNoAverages?.['1st_serve']?.overall}
                dataWithAverages={dataWithAverages?.['1st_serve']?.overall}
                showAverages={showAverages}
                player="player1"
                isPerformanceOverviewPage={isPerformanceOverviewPage}
                {...rest}
              />
            }
            middle="Effectiveness"
            right={
              <OverallBadges
                dataNoAverages={dataNoAverages?.['2nd_serve']?.overall}
                dataWithAverages={dataWithAverages?.['2nd_serve']?.overall}
                showAverages={showAverages}
                player="player1"
                isPerformanceOverviewPage={isPerformanceOverviewPage}
                {...rest}
              />
            }
          />
        )}
        {!isServeEffectiveness && (
          <Flex direction="column" gap={3}>
            <OverallRow
              isSingleColumn={isScout}
              left={
                <OverallBadges
                  dataNoAverages={dataNoAverages?.overall_core}
                  dataWithAverages={dataWithAverages?.overall_core}
                  showAverages={showAverages}
                  player="player1"
                  lowerValue={
                    dataNoAverages?.overall_core?.player1?.score < dataNoAverages?.overall_core?.player2?.score
                  }
                  isPerformanceOverviewPage={isPerformanceOverviewPage}
                  {...rest}
                />
              }
              middle="Core Shots"
              right={
                <OverallBadges
                  dataNoAverages={dataNoAverages?.overall_core}
                  dataWithAverages={dataWithAverages?.overall_core}
                  showAverages={showAverages}
                  player="player2"
                  lowerValue={
                    dataNoAverages?.overall_core?.player2?.score < dataNoAverages?.overall_core?.player1?.score
                  }
                  isPerformanceOverviewPage={isPerformanceOverviewPage}
                  {...rest}
                />
              }
            />
            <OverallRow
              isSingleColumn={isScout}
              left={
                <OverallBadges
                  dataNoAverages={dataNoAverages?.overall_variation}
                  dataWithAverages={dataWithAverages?.overall_variation}
                  showAverages={showAverages}
                  player="player1"
                  lowerValue={
                    dataNoAverages?.overall_variation?.player1?.score <
                    dataNoAverages?.overall_variation?.player2?.score
                  }
                  isPerformanceOverviewPage={isPerformanceOverviewPage}
                  {...rest}
                />
              }
              middle="Variation Shots"
              right={
                <OverallBadges
                  dataNoAverages={dataNoAverages?.overall_variation}
                  dataWithAverages={dataWithAverages?.overall_variation}
                  showAverages={showAverages}
                  player="player2"
                  lowerValue={
                    dataNoAverages?.overall_variation?.player2?.score <
                    dataNoAverages?.overall_variation?.player1?.score
                  }
                  isPerformanceOverviewPage={isPerformanceOverviewPage}
                  {...rest}
                />
              }
            />
          </Flex>
        )}
        {isScout && isServeEffectiveness ? (
          <Flex justify="space-between">
            <Flex justify="center" w="100%">
              <SingleStackedBar
                dataNoAverages={dataNoAverages?.['1st_serve']?.breakdown_scores}
                dataWithAverages={dataWithAverages?.['1st_serve']?.breakdown_scores}
                player="player1"
                customStackColors={customStackColors}
                isServeEffectiveness={isServeEffectiveness}
                {...rest}
              />
            </Flex>
            <Box w={middleColumnWidth} h="20px" />
            <Flex justify="center" w="100%">
              <SingleStackedBar
                dataNoAverages={dataNoAverages?.['2nd_serve']?.breakdown_scores}
                dataWithAverages={dataWithAverages?.['2nd_serve']?.breakdown_scores}
                player="player1"
                customStackColors={customStackColors}
                isServeEffectiveness={isServeEffectiveness}
                {...rest}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex justify="space-between">
            <Flex justify="center" w="100%">
              <SingleStackedBar
                dataNoAverages={dataNoAverages?.breakdown_scores}
                dataWithAverages={dataWithAverages?.breakdown_scores}
                player="player1"
                customStackColors={customStackColors}
                isServeEffectiveness={isServeEffectiveness}
                {...rest}
              />
            </Flex>
            {isMatch && (
              <>
                <Box w={middleColumnWidth} h="20px" />
                <Flex justify="center" w="100%">
                  <SingleStackedBar
                    dataNoAverages={dataNoAverages?.breakdown_scores}
                    dataWithAverages={dataWithAverages?.breakdown_scores}
                    player="player2"
                    customStackColors={customStackColors}
                    isServeEffectiveness={isServeEffectiveness}
                    {...rest}
                  />
                </Flex>
              </>
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

BreakdownMetrics.propTypes = {
  dataNoAverages: PropTypes.object.isRequired,
  dataWithAverages: PropTypes.object,
  players: PropTypes.object,
  showAverages: PropTypes.bool,
  averagesLoading: PropTypes.bool,
  graphicType: PropTypes.oneOf(['serve_effectiveness', 'shot_variation']),
  isScout: PropTypes.bool,
  serveType: PropTypes.oneOf([1, 2])
};

const OverallBadges = ({
  dataNoAverages,
  dataWithAverages,
  showAverages,
  player,
  averagesLoading,
  lowerValue,
  isPerformanceOverviewPage = false
}) => {
  const displayBadge = (data, displayVariant, fieldNameVariant) => (
    <CourtBadge
      text={displayScore(data?.[`${player}`]?.[`${fieldNameVariant}`], data?.[`${player}`]?.score_type)}
      variant={displayVariant}
      loading={fieldNameVariant === 'score' ? false : averagesLoading}
      size="large"
    />
  );

  return (
    <>
      {showAverages ? (
        <Flex direction="column" gap={1}>
          {displayBadge(dataNoAverages, lowerValue ? 'primaryLight' : 'primary', 'score')}
          {displayBadge(dataWithAverages, 'playerAverage', 'player_average')}
          {isPerformanceOverviewPage
            ? displayBadge(dataNoAverages, 'tourAverage', 'tour_average')
            : displayBadge(dataWithAverages, 'tourAverage', 'tour_average')}
        </Flex>
      ) : (
        <Flex direction="column" gap={1}>
          {displayBadge(dataNoAverages, lowerValue ? 'primaryLight' : 'primary', 'score')}
          {isPerformanceOverviewPage && displayBadge(dataNoAverages, 'tourAverage', 'tour_average')}
        </Flex>
      )}
    </>
  );
};

const OverallRow = ({ left, middle, right, isSingleColumn }) =>
  isSingleColumn ? (
    <Flex justify="center">
      <Flex direction="column" gap={2} align="center">
        <Text fontWeight="bold" fontSize="sm" textAlign="center">
          {middle}
        </Text>
        <Box w="50px">{left}</Box>
      </Flex>
    </Flex>
  ) : (
    <Flex justify="space-between" position="relative" minH="40px">
      <Flex justify="center" w="100%" align="center">
        <Box>{left}</Box>
      </Flex>
      <Box w={middleColumnWidth} />
      <Text
        w="120px"
        fontWeight="bold"
        fontSize="sm"
        textAlign="center"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        {middle}
      </Text>
      <Flex justify="center" w="100%" align="center">
        <Box>{right}</Box>
      </Flex>
    </Flex>
  );

const ColumnHeadings = ({ left, right }) => (
  <Flex justify="space-between">
    <Flex justify="center" w="100%">
      <Text fontSize="sm" fontWeight="semibold">
        {left}
      </Text>
    </Flex>
    <Box w={middleColumnWidth} h="20px" />
    <Flex justify="center" w="100%">
      <Text fontSize="sm" fontWeight="semibold">
        {right}
      </Text>
    </Flex>
  </Flex>
);

export default BreakdownMetrics;
