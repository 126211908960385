import React, { useRef, useEffect, useState } from 'react';

import { Box, Button, ButtonGroup } from '@chakra-ui/react';

interface TabProps {
  options: string[];
  activeIndex: number;
  onChange: (index: number) => void;
  activeColor: string;
  inactiveColor: string;
  activeTextColor: string;
  inactiveTextColor: string;
}

const Tab: React.FC<TabProps> = ({
  options,
  activeIndex,
  onChange,
  activeColor = 'primary.500',
  activeTextColor = 'secondary.500',
  inactiveTextColor = 'black'
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const [indicatorStyle, setIndicatorStyle] = useState({
    width: 0,
    left: 0
  });

  useEffect(() => {
    const updateIndicator = () => {
      const activeButton = buttonsRef.current[activeIndex];
      const container = containerRef.current;

      if (activeButton && container) {
        const buttonRect = activeButton.getBoundingClientRect();

        setIndicatorStyle({
          width: buttonRect.width,
          left: activeButton.offsetLeft
        });
      }
    };

    updateIndicator();
    // Add resize listener to handle window size changes
    window.addEventListener('resize', updateIndicator);
    return () => window.removeEventListener('resize', updateIndicator);
  }, [activeIndex]);

  return (
    <Box display="block" w="100%" position="relative">
      <ButtonGroup
        ref={containerRef}
        size="md"
        isAttached
        spacing="0"
        borderRadius="full"
        p="1"
        bg="white"
        borderWidth="1px"
        borderColor="gray.400"
        w="100%"
        overflow="hidden"
        position="relative"
      >
        <Box
          position="absolute"
          height="calc(100% - 8px)"
          bg={activeColor}
          borderRadius="full"
          transition="all 0.3s ease"
          style={{
            width: indicatorStyle.width,
            left: indicatorStyle.left
          }}
        />

        {options.map((label, index) => (
          <Button
            key={index}
            ref={(el) => (buttonsRef.current[index] = el)}
            onClick={() => onChange(index)}
            borderRadius="full"
            sx={{
              borderRadius: 'full !important'
            }}
            bg="transparent"
            color={activeIndex === index ? activeTextColor : inactiveTextColor}
            _hover={{
              bg: 'transparent'
            }}
            transition="all 0.2s"
            px="6"
            flex="1"
            mr={index < options.length - 1 ? '-1px' : '0'}
            position="relative"
            zIndex="1"
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default Tab;
