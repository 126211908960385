import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Text
} from '_shared/designSystem/components';

export const SubHeading = ({ children }) => (
  <Text color="secondary.500" fontSize="xl" fontWeight="bold">
    {children}
  </Text>
);

interface AccordionGroupProps {
  /**
   * The title displayed at the top of the parent accordion.
   * This is typically the header for the group of child accordions rendered within it.
   */
  title: string;

  /**
   * The content to be rendered inside the parent accordion.
   * This can include multiple child accordions or any other JSX elements.
   */
  children: React.ReactNode;

  /**
   * A callback function triggered when the toggle button for the parent accordion is clicked, which toggles the visibility of the items
   */
  onToggle: () => void;
}

const AccordionGroup = ({ title, children, onToggle }: AccordionGroupProps) => (
  <Box bg="white" p={4} borderRadius="lg" mb={4}>
    <Accordion defaultIndex={[0]} allowToggle>
      <AccordionItem border="none">
        <Flex align="center" justify="space-between">
          <Box flex="1" textAlign="center">
            <SubHeading>{title}</SubHeading>
          </Box>
          <AccordionButton onClick={() => onToggle()} pb={4} w="auto" bg="transparent" _hover={{ bg: 'grey.50' }}>
            <AccordionIcon />
          </AccordionButton>
        </Flex>
        <AccordionPanel pt={0} px={0}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </Box>
);

export default AccordionGroup;
