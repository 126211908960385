import { useMemo, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { displayScore } from 'match/utils/transformations';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Flex, Text, Progress, VStack } from '_shared/designSystem/components';
import { tvD3ComponentColors } from '_shared/designSystem/theme/chartColors';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

import { SUB_KEYS, TOP_KEYS } from './constants';
import RallyLengthHeader from './RallyLengthHeader';
import RallyLengthServeAndReturnView from './RallyLengthServeAndReturnView';
import { mergeDataWithAverages } from './rallyLengthUtils';
import TShapeIndicator from './TShapeIndicator';

const RallyLengthChart = ({ dataNoAverages, dataWithAverages, showAverages }) => {
  // Toggle states
  const [showByServeReturn, setShowByServeReturn] = useState(false);
  const [showWinPercentage, setShowWinPercentage] = useState(false);

  // Color Palette
  const colorPalette = customSitesConfig?.d3ComponentColors || tvD3ComponentColors;
  const { shared, rallyLength } = colorPalette;

  const getBarColor = (index: number) => {
    return rallyLength.bars[index % rallyLength.bars.length];
  };

  const topKey = showByServeReturn ? TOP_KEYS.SERVE_AND_RETURN : TOP_KEYS.ALL_POINTS;
  const subKey = showWinPercentage ? SUB_KEYS.WON : SUB_KEYS.PLAYED;

  const mergedData = useMemo(() => {
    if (isEmpty(dataNoAverages)) return null;
    return mergeDataWithAverages(topKey, subKey, dataNoAverages, dataWithAverages);
  }, [topKey, subKey, dataNoAverages, dataWithAverages]);

  const displayedData = useMemo(() => {
    if (isEmpty(mergedData)) return { type: 'all_points', data: [] };
    return {
      type: topKey,
      data: mergedData
    };
  }, [topKey, mergedData]);

  // Single bar with label component
  const BarWithLabel = ({ data, index }) => {
    // This is to align the score text to the left when either the tour average or player average is less than 60% of the score
    const alignTextLeft =
      data.tour_average <= data.score * 0.6 || (showAverages && data.player_average <= data.score * 0.6);

    return (
      <Flex width="100%" mb={4} position="relative">
        {/* Left-aligned two-line label */}
        <Box width="70px">
          <VStack spacing={0} alignItems="flex-start" justifyContent="center" height="40px">
            <Text fontSize="xs">{data.label}</Text>
          </VStack>
        </Box>

        {/* Bar and indicators container */}
        <Box flex="1" position="relative">
          <Progress
            value={data.score}
            h="40px"
            bg={rallyLength.grey}
            sx={{
              '& > div': {
                background: getBarColor(index),
                transition: 'width 0.2s ease-in-out'
              }
            }}
          />

          {/* Centered or left-aligned score text */}
          <Flex
            position="absolute"
            top="0"
            left="0"
            width={`${data.score}%`}
            height="40px"
            alignItems="center"
            justifyContent={alignTextLeft ? 'flex-start' : 'center'}
            pl={alignTextLeft ? 2 : 0}
          >
            <Text color={rallyLength.score} fontSize="xs" fontWeight="semibold">
              {displayScore(data.score, data.score_type)}
            </Text>
          </Flex>

          {/* T-shape indicators */}
          {data.tour_average !== null && (
            <TShapeIndicator
              score={data.score}
              average={data.tour_average}
              color={shared.tourAverage}
              textColor={isWimbPortal() ? rallyLength.black : shared.white}
              type="tourAverage"
              showAverages={showAverages}
            />
          )}
          {showAverages && data.player_average !== null && (
            <TShapeIndicator
              score={data.score}
              average={data.player_average}
              color={shared.playerAverage}
              textColor={rallyLength.black}
              type="playerAverage"
              showAverages={showAverages}
            />
          )}
        </Box>
      </Flex>
    );
  };

  if (isEmpty(displayedData?.data)) return null;

  return (
    <Box p={{ base: 2, sm: 4, md: 6 }} boxSizing="border-box">
      <RallyLengthHeader
        onToggleByServeReturn={() => setShowByServeReturn((prev) => !prev)}
        isToggledByServeReturn={showByServeReturn}
        onToggleWinPercentage={() => setShowWinPercentage((prev) => !prev)}
        isToggledWinPercentage={showWinPercentage}
        showAverages={showAverages}
      />

      {!showByServeReturn ? (
        <Box w="100%" mt={4}>
          {displayedData.type === TOP_KEYS.ALL_POINTS &&
            displayedData.data.map((data, index) => <BarWithLabel key={data.id} data={data} index={index} />)}
        </Box>
      ) : (
        <Flex width="100%" height="100%" direction={{ base: 'column', lg: 'row' }} gap={16} mt={6}>
          <Box width={{ base: '100%', lg: '50%' }}>
            <Text fontSize="sm" fontWeight="semibold" mb={2} textAlign="center">
              Points on Serve
            </Text>
            {displayedData.type === TOP_KEYS.SERVE_AND_RETURN && (
              <RallyLengthServeAndReturnView data={displayedData.data} type="serve" showAverages={showAverages} />
            )}
          </Box>
          <Box width={{ base: '100%', lg: '50%' }}>
            <Text fontSize="sm" fontWeight="semibold" mb={2} textAlign="center">
              Points on Return
            </Text>
            {displayedData.type === TOP_KEYS.SERVE_AND_RETURN && (
              <RallyLengthServeAndReturnView data={displayedData.data} type="return" showAverages={showAverages} />
            )}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default RallyLengthChart;
