export const scoutTabTypes = {
  INSIGHTS: 'insights',
  PERFORMANCE_OVERVIEW: 'performance-overview',
  SERVE: 'serve',
  SERVE_V2: 'serve-v2',
  RETURN: 'return',
  GROUNDSTROKES: 'groundstrokes',
  CONTACT_POINTS: 'contact-points',
  WINNING_AND_LOSING_PLAYS: 'winning-and-losing-plays',
  PATTERNS_OF_PLAY: 'patterns-of-play'
};

export const patternsOfPlayGraphicType = {
  DIRECTION: 'direction',
  IN: 'in',
  QUALITY: 'quality',
  DEPTH: 'depth',
  ACCURACY: 'accuracy'
};

export const patternsOfPlayPattern = {
  CONTROL_THE_MIDDLE: 'control_the_middle',
  FROM_THE_DEUCE_SIDE: 'from_the_deuce_side',
  FROM_THE_ADVANTAGE_SIDE: 'from_the_advantage_side',
  VOLLEYS: 'volleys'
};

export const patternsOfPlayShotTypes = {
  FOREHAND: 'forehand',
  BACKHAND: 'backhand',
  GROUNDSTROKE: 'groundstroke'
};

export const MATCH_SELECTOR_COLUMNS = {
  DATE: 'Date',
  YEAR: 'Year',
  TOURNAMENT_NAME: 'Tournament',
  OPPONENT_NAME: 'Opponent',
  RESULT: 'Result',
  SCORE: 'Score',
  ROUND: 'Round',
  SURFACE: 'Surface',
  OPPONENT_RANK: 'Opponent Rank',
  OPPONENT_HAND: 'Opponent Hand'
};

export const MENU_ITEMS_CONFIG = [
  {
    id: scoutTabTypes.INSIGHTS,
    label: 'Insights'
  },
  {
    id: scoutTabTypes.PERFORMANCE_OVERVIEW,
    label: 'Performance Overview',
    featureFlag: 'performanceOverviewEnabled',
    permission: 'scout_performance_overview'
  },
  {
    id: scoutTabTypes.SERVE,
    label: 'Serve'
  },
  {
    id: scoutTabTypes.SERVE_V2,
    label: 'Serve V2',
    featureFlag: 'serveV2Enabled'
  },
  {
    id: scoutTabTypes.RETURN,
    label: 'Return'
  },
  {
    id: scoutTabTypes.GROUNDSTROKES,
    label: 'Groundstrokes'
  },
  {
    id: scoutTabTypes.WINNING_AND_LOSING_PLAYS,
    label: 'Winning and Losing Plays',
    featureFlag: 'winningAndLosingPlaysV2Enabled'
  },
  {
    id: scoutTabTypes.PATTERNS_OF_PLAY,
    label: 'Patterns of Play',
    featureFlag: 'patternsOfPlayEnabled'
  }
];
