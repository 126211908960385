import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Skeleton
} from '_shared/designSystem/components';

// Props interface for the AccordionSingle component
interface AccordionSingleProps {
  /**
   * The data to be displayed in the accordion.
   * Each item in the array represents a single accordion item with:
   * - `title`: The header text displayed on the accordion button.
   * - `content`: The content displayed inside the accordion panel when expanded.
   */
  data?: { title: string; content: string }[];

  /**
   * A callback function triggered when an accordion item is clicked.
   * The function is called with the index of the clicked accordion item.
   * Use this to handle state updates for tracking which accordion items are open.
   *
   * @param index - The index of the accordion item that was clicked.
   */
  onChange: (index: number) => void;

  /**
   * Determines if the component is in a loading state
   */
  isLoading?: boolean;

  /**
   * Number of skeleton items to show while loading
   * @default 3
   */
  skeletonCount?: number;
}

const AccordionSingle = ({ data = [], onChange, isLoading = false, skeletonCount = 3 }: AccordionSingleProps) => {
  if (isLoading) {
    return (
      <Accordion allowMultiple>
        {[...Array(skeletonCount)].map((_, index) => (
          <AccordionItem key={`skeleton-${index}`} border="0" borderBottom="1px solid" borderColor="gray.200">
            <h2>
              <AccordionButton
                bgColor="white"
                borderRadius={8}
                my={2}
                p={2}
                _hover={{ cursor: 'default' }}
                onClick={(e) => e.preventDefault()}
              >
                <Box flex="1" textAlign="left">
                  <Skeleton height="22px" width="100%" />
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Skeleton height="22px" />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    );
  }

  return (
    <Accordion allowMultiple>
      {data.map((item, index) => (
        <AccordionItem key={index} border="0" borderBottom="1px solid" borderColor="gray.200">
          <h2>
            <AccordionButton bgColor="white" borderRadius={8} my={2} p={2} onClick={() => onChange(index)}>
              <Box flex="1" textAlign="left" color="secondary.500" fontWeight="bold">
                {item.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>{item.content}</AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default AccordionSingle;
