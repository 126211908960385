import { parseISO, format } from 'date-fns';

// Helper function to consistently parse UTC dates and format them
const formatUTCDate = (date: string, formatString: string): string => {
  if (!date) return '';

  try {
    const parsedDate = parseISO(date.replace(' ', 'T') + 'Z');
    if (!parsedDate) return '';
    return format(parsedDate, formatString);
  } catch (error) {
    console.error('Invalid date format:', error);
    return '';
  }
};

export function formatDate(date: string) {
  return formatUTCDate(date, 'd MMM yyyy');
}

export function formatDateShortYear(dateString: string) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: '2-digit'
  });
}

export function formatDateNoYear(date: string) {
  return formatUTCDate(date, 'd MMM');
}

export const formatDateYearOnly = (date: string) => {
  return formatUTCDate(date, 'yyyy');
};

export function formatTime(date: string) {
  return formatUTCDate(date, 'HH:mm');
}

export function convertMinToHourMin(totalMinutes: number) {
  if (!totalMinutes) return '';
  try {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes.toString().padStart(2, '0')}m`;
  } catch (error) {
    console.error('Invalid duration format:', error);
    return '';
  }
}
