import { useMemo, useState } from 'react';

import {
  Box,
  Flex,
  SimpleGrid,
  Container,
  Text,
  useBreakpointValue,
  AccordionGroup,
  AccordionSingle,
  Tab
} from '_shared/designSystem/components';

import { useAdvancedCourtGraphics, useAdvancedStats, useCourtGraphics, useStats } from '../hooks/dataFetchingHooks';
import { ServeAccordionGroupType } from '../types/types';

import { ServeAccordionGroup, ServeAccordionGroupTitle } from './constants';
import { toggleAccordionGroup } from './utils';

export const Serve = () => {
  // state management
  const [activeIndex, setActiveIndex] = useState(0);
  const [accordionGroups, setAccordion] = useState({
    courtGraphics: [],
    advancedCourtGraphics: [],
    stats: [],
    advancedStats: []
  });

  // data fetching
  const { data: courtGraphicsData = [], isLoading: courtGraphicsLoading } = useCourtGraphics();
  const { data: courtGraphicsAdvancedData = [], isLoading: courtGraphicsAdvancedLoading } = useAdvancedCourtGraphics();
  const { data: statsData = [], isLoading: statsLoading } = useStats();
  const { data: statsAdvancedData = [], isLoading: statsAdvancedLoading } = useAdvancedStats();

  // Responsive column configuration
  const columns = useBreakpointValue({
    base: 1,
    md: 3
  });
  const isTablet = useBreakpointValue({ base: true, md: true, lg: false });

  // Accordion change handler
  const handleAccordionSingleChange = (accordionGroupKey: ServeAccordionGroupType) => (index: number) => {
    setAccordion((prev) => toggleAccordionGroup(prev, accordionGroupKey, index));
  };

  // Combined data for mobile views
  const combinedCourtGraphicsData = useMemo(() => {
    return courtGraphicsData.concat(courtGraphicsAdvancedData);
  }, [courtGraphicsData, courtGraphicsAdvancedData]);

  const combinedStatsData = useMemo(() => {
    return statsData.concat(statsAdvancedData);
  }, [statsData, statsAdvancedData]);

  const renderMobileView = () => {
    if (activeIndex === 0) {
      return (
        <AccordionSingle
          data={combinedCourtGraphicsData}
          onChange={handleAccordionSingleChange(ServeAccordionGroup.COURT_GRAPHICS)}
          isLoading={courtGraphicsLoading || courtGraphicsAdvancedLoading}
          skeletonCount={10}
        />
      );
    }

    return (
      <AccordionSingle
        data={combinedStatsData}
        onChange={handleAccordionSingleChange(ServeAccordionGroup.STATS)}
        isLoading={statsLoading || statsAdvancedLoading}
        skeletonCount={4}
      />
    );
  };

  const renderDesktopView = () => (
    <SimpleGrid columns={columns} spacing={4}>
      {/* Court Graphics and Advanced Court Graphics - 2 columns wide */}
      <Box gridColumn={{ base: 'span 1', md: 'span 2' }}>
        <AccordionGroup
          title={ServeAccordionGroupTitle.COURT_GRAPHICS}
          onToggle={() => toggleAccordionGroup(accordionGroups, ServeAccordionGroup.COURT_GRAPHICS, 0)}
        >
          <AccordionSingle
            data={courtGraphicsData}
            onChange={handleAccordionSingleChange(ServeAccordionGroup.COURT_GRAPHICS)}
            isLoading={courtGraphicsLoading}
            skeletonCount={4}
          />
        </AccordionGroup>

        <AccordionGroup
          title={ServeAccordionGroupTitle.ADVANCED_COURT_GRAPHICS}
          onToggle={() => toggleAccordionGroup(accordionGroups, ServeAccordionGroup.ADVANCED_COURT_GRAPHICS, 1)}
        >
          <AccordionSingle
            data={courtGraphicsAdvancedData}
            onChange={handleAccordionSingleChange(ServeAccordionGroup.ADVANCED_COURT_GRAPHICS)}
            isLoading={courtGraphicsAdvancedLoading}
            skeletonCount={6}
          />
        </AccordionGroup>
      </Box>

      {/* Stats and Advanced Stats - 1 column wide */}
      <Box gridColumn={{ base: 'span 1', md: 'span 1' }}>
        <AccordionGroup
          title={ServeAccordionGroupTitle.STATS}
          onToggle={() => toggleAccordionGroup(accordionGroups, ServeAccordionGroup.STATS, 2)}
        >
          <AccordionSingle
            data={statsData}
            onChange={handleAccordionSingleChange(ServeAccordionGroup.STATS)}
            isLoading={statsLoading}
            skeletonCount={2}
          />
        </AccordionGroup>

        <AccordionGroup
          title={ServeAccordionGroupTitle.ADVANCED_STATS}
          onToggle={() => toggleAccordionGroup(accordionGroups, ServeAccordionGroup.ADVANCED_STATS, 3)}
        >
          <AccordionSingle
            data={statsAdvancedData}
            onChange={handleAccordionSingleChange(ServeAccordionGroup.ADVANCED_STATS)}
            isLoading={statsAdvancedLoading}
            skeletonCount={4}
          />
        </AccordionGroup>
      </Box>
    </SimpleGrid>
  );

  return (
    <Container maxW="100%" p={0} m={0}>
      <Flex justify="center" width="100%" mb={6}>
        {isTablet ? (
          <Tab
            options={['Court Graphics', 'Stats']}
            activeIndex={activeIndex}
            onChange={setActiveIndex}
            activeColor="black"
            activeTextColor="white"
            inactiveTextColor="black"
            inactiveColor="white"
          />
        ) : (
          <Text color="grey.500">Desktop filter controls will be added here</Text>
        )}
      </Flex>

      <Box bg="grey.100" p={6} borderRadius="lg">
        {isTablet ? renderMobileView() : renderDesktopView()}
      </Box>
    </Container>
  );
};

export default Serve;
