import { courtGraphics, courtGraphicsAdvanced, tables, tablesAdvanced } from '../components/constants';
import { AdvancedCourtGraphic, AdvancedStatTable, CourtGraphic, StatTable } from '../types/types';

// Utility function to simulate network delay
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Service layer with type safety and 2 second delays
export const serveService = {
  async getCourtGraphics(): Promise<{ data: CourtGraphic[] }> {
    try {
      await delay(2000);
      return Promise.resolve({ data: courtGraphics });
    } catch (error) {
      throw new Error('Failed to fetch court graphics');
    }
  },

  async getAdvancedCourtGraphics(): Promise<{ data: AdvancedCourtGraphic[] }> {
    try {
      await delay(2000);
      return Promise.resolve({ data: courtGraphicsAdvanced });
    } catch (error) {
      throw new Error('Failed to fetch advanced court graphics');
    }
  },

  async getStats(): Promise<{ data: StatTable[] }> {
    try {
      await delay(2000);
      return Promise.resolve({ data: tables });
    } catch (error) {
      throw new Error('Failed to fetch stats');
    }
  },

  async getAdvancedStats(): Promise<{ data: AdvancedStatTable[] }> {
    try {
      await delay(2000);
      return Promise.resolve({ data: tablesAdvanced });
    } catch (error) {
      throw new Error('Failed to fetch advanced stats');
    }
  }
};
