import { courtGraphicTypes } from 'match/_shared/matchConstants';
import { getCoordinates, getCourtGraphic3d2d, isContactPoint } from 'match/utils/courtGraphicsUtil';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { getReduceBallSizeAmount } from 'scout/util/scoutCourtGraphicsUtil';

import { Flex, Box } from '_shared/designSystem/components';

import AveragesBadges from './averages/AveragesBadges';
import CourtBallDotShaded from './CourtBallDotShaded';
import CourtBallDotsSvg from './CourtBallDotsSvg';
import { CourtGraphicsLegend } from './CourtGraphicsLegend';
import CourtSurface2dRally from './CourtSurface2dRally';
import CourtSurface2dReturn from './CourtSurface2dReturn';
import CourtSurface3d from './CourtSurface3d';
import { sortShotsByDisplayType } from './utils';

export default function CourtContainer(props) {
  const { surface, shots, graphicType, orientation, outShots } = props;
  const [ref, { width, height }] = useMeasure();

  if (!surface || !graphicType) return null;

  const backgroundColor = `court.background.${surface}`;

  /**
   * Below this limit we show the individual dom element shaded balls, which look nicer but have lag when there's a lot of them.
   * Above this number we show a single SVG which combines all dots - has no shading but has no lag.
   */
  const shadedBallsLimit = 500;

  return (
    <Box data-testid="courtContainer">
      <Flex
        position="relative"
        direction="column"
        justify="center"
        w="100%"
        bg={backgroundColor}
        px={10}
        overflow="hidden"
        pb={[courtGraphicTypes.RETURN_PLACEMENT, courtGraphicTypes.RALLY_PLACEMENT].includes(graphicType) ? 7 : 0}
      >
        <Flex w="100%" position="relative" ref={ref}>
          {shots.length < shadedBallsLimit && getShadedBallDots(shots, width, height, surface, graphicType)}
          {shots.length >= shadedBallsLimit && width > 0 && height > 0 && (
            <CourtBallDotsSvg shots={shots} width={width} height={height} graphicType={graphicType} surface={surface} />
          )}
          {height > 0 && <AveragesBadges width={width} height={height} {...props} />}
          {getCourtGraphic3d2d(graphicType) === '3d' && <CourtSurface3d {...props} width={width} height={height} />}
          {graphicType === courtGraphicTypes.RETURN_CONTACT_POINT && (
            <CourtSurface2dReturn {...props} width={width} height={height} />
          )}
          {graphicType === courtGraphicTypes.RALLY_CONTACT_POINT && (
            <CourtSurface2dRally {...props} width={width} height={height} />
          )}
          {isContactPoint(graphicType) && (
            <CourtGraphicsLegend
              graphicType={graphicType}
              orientation={orientation}
              surface={surface}
              width={width}
              height={height}
              outShots={outShots}
            />
          )}
        </Flex>
        {!isContactPoint(graphicType) && (
          <CourtGraphicsLegend
            graphicType={graphicType}
            orientation={orientation}
            surface={surface}
            width={width}
            height={height}
            outShots={outShots}
          />
        )}
      </Flex>
    </Box>
  );
}

CourtContainer.defaultProps = {
  surface: 'hard',
  shots: []
};

CourtContainer.propTypes = {
  surface: PropTypes.oneOf(['hard', 'clay', 'grass', 'indoor']),
  shots: PropTypes.array,
  graphicType: PropTypes.oneOf([
    'serve_direction',
    'return_contact_point',
    'return_placement',
    'rally_contact_point',
    'rally_placement'
  ]),
  orientation: PropTypes.string,
  dataNoAverages: PropTypes.object,
  showAverages: PropTypes.bool,
  averagesLoading: PropTypes.bool,
  outShots: PropTypes.bool
};

function getShadedBallDots(shots, width, height, surface, graphicType) {
  const sortedShots = sortShotsByDisplayType(shots);
  const ballSizeReductionObject = getReduceBallSizeAmount(sortedShots, graphicType, width);
  let ballDiameter = Math.round(ballSizeReductionObject.diameterRatio * width);
  if (ballDiameter < ballSizeReductionObject.minDiameter) ballDiameter = ballSizeReductionObject.minDiameter;

  return (
    <Box>
      {sortedShots.map((item, index) => {
        const coordinates = getCoordinates(item, width, height, ballDiameter, graphicType);
        if (item.x === null || item.y === null) return null;
        if (coordinates.left > width || coordinates.top > height) return null;
        return (
          <CourtBallDotShaded
            key={index}
            diameter={ballDiameter}
            positionFromTop={coordinates?.top}
            positionFromLeft={coordinates?.left}
            surface={surface}
            courtAngle={getCourtGraphic3d2d(graphicType)}
            displayType={item.display_type}
          />
        );
      })}
    </Box>
  );
}
