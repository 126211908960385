import React, { useEffect, useState } from 'react';

import { useSearch } from '@tanstack/react-location';
import { graphicsService } from 'graphics/services/graphicsService';

import usePageTitle from '_shared/utils/hooks/usePageTitle';

import GraphicContainer from './GraphicContainer';
import { AspectRatio, GraphicType, MatchWinner, PlayerRankings } from './types';

const GraphicFullScreen: React.FC = () => {
  usePageTitle('Graphic Full Screen');

  const search = useSearch();

  const graphicTypeParam = (search.graphic_type as GraphicType) || GraphicType.MATCH_WINNER;
  const matchIdParam = (search.match_id as string) || '';
  const aspectRatioParam = (search.aspect_ratio as AspectRatio) || AspectRatio['4x5'];

  const [graphicData, setGraphicData] = useState<MatchWinner | PlayerRankings | null>(null);

  useEffect(() => {
    if (matchIdParam) {
      graphicsService
        .generateGraphicData({
          queryParams: {
            graphicType: graphicTypeParam,
            match_id: matchIdParam
          }
        })
        .then((data: MatchWinner | PlayerRankings) => {
          setGraphicData(data);
        })
        .catch((error) => {
          console.error('Error fetching graphic data:', error);
          setGraphicData(null);
        });
    }
  }, [graphicTypeParam, matchIdParam]);

  return graphicData ? (
    <GraphicContainer graphicType={graphicTypeParam} aspectRatio={aspectRatioParam} data={graphicData} />
  ) : null;
};

export default GraphicFullScreen;
