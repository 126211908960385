import { Box } from '_shared/designSystem/components';

import { PlayerRankings } from '../../types';

type PlayerRanking9x16Props = {
  data: PlayerRankings;
};

const PlayerRanking9x16 = ({ data }: PlayerRanking9x16Props) => {
  return <Box></Box>;
};

export default PlayerRanking9x16;
