import { useQuery } from 'react-query';

import { serveService } from '../service/ServeService';

export const useCourtGraphics = () => {
  return useQuery({
    queryKey: ['serveService_courtGraphics'],
    queryFn: () => serveService.getCourtGraphics(),
    select: (response) => response.data,
    onError: (error: Error) => {
      console.error('Court Graphics Error:', error.message);
    }
  });
};

export const useAdvancedCourtGraphics = () => {
  return useQuery({
    queryKey: ['serveService_advancedCourtGraphics'],
    queryFn: () => serveService.getAdvancedCourtGraphics(),
    select: (response) => response.data,
    onError: (error: Error) => {
      console.error('Advanced Court Graphics Error:', error.message);
    }
  });
};

export const useStats = () => {
  return useQuery({
    queryKey: ['serveService_stats'],
    queryFn: () => serveService.getStats(),
    select: (response) => response.data,
    onError: (error: Error) => {
      console.error('Stats Error:', error.message);
    }
  });
};

export const useAdvancedStats = () => {
  return useQuery({
    queryKey: ['serveService_advancedStats'],
    queryFn: () => serveService.getAdvancedStats(),
    select: (response) => response.data,
    onError: (error: Error) => {
      console.error('Advanced Stats Error:', error.message);
    }
  });
};
